<template>
  <div class="">
    <div class="">
      <v-card class="">
        <!-- logo -->
        <v-card-title class="d-flex py-7">
          <h2 class="text-2xl font-weight-semibold">
            Notifications
          </h2>
          <v-spacer>
          </v-spacer>
          <!--          <v-btn
                      color="primary"
                      small
                    >
                      Marquer comme toutes lues
                    </v-btn>-->
        </v-card-title>


        <!-- login form -->
        <v-card-text>
          <notification-component v-if="user.id" :user="user">
            <template #empty>
              <v-card elevation="0" v-for="n in 6"
                      :color="`grey darken-2 `"
                      :key="n"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  :height="72"
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
              </v-card>
            </template>
          </notification-component>

        </v-card-text>
      </v-card>
    </div>

  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiClose,
  mdiArrowRight,
  mdiCreditCardOutline,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import {ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import {useRouter} from '@/utils'
import NotificationComponent from "@/components/NotificationComponent.vue";

export default {
  components: {NotificationComponent},
  beforeRouteEnter(to, from, next) {
    console.log('beforeRouteEnter', to, from)
    // appelée avant que la route vers le composant soit confirmée.
    // cette fonction n'a pas accès à l'instance du composant avec `this`,
    // car le composant n'a pas encore été créé quand cette interception est appelée !
    next();
  },
  /*beforeRouteUpdate(to, from, next) {
    console.log('beforeRouteUpdate', to, from)
    // appelée quand la route qui fait le rendu de ce composant change,
    // mais que ce composant est utilisé de nouveau dans la nouvelle route.
    // Par exemple, pour une route avec le paramètre dynamique `/foo/:id`, quand nous
    // naviguons entre `/foo/1` et `/foo/2`, la même instance du composant `Foo`
    // va être réutilisée, et ce hook va être appelé quand cela arrivera.
    // ce hook a accès à l'instance de ce composant via `this`.
  },*/
  beforeRouteLeave(to, from, next) {
    console.log('beforeRouteLeave', to, from)
    // appelée quand la route qui fait le rendu de ce composant est sur le point
    // d'être laissée en faveur de la prochaine route.
    // elle a accès à l'instance de ce composant via `this`.
    if (from.path === '/')
      this.$router.push('/home')
    else
      next();
  },
  setup() {
    const user = Drequest.getUser()
    const env = Drequest.__env


    return {
      user,
      env,

      icons: {
        mdiClose,
        mdiEyeOutline,
        mdiArrowRight,
        mdiCreditCardOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
